import React from 'react';
import Footer from '../components/Footer';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import NavBar from '../components/Navbar/NavBar';


const Service = () => {
    return (
        <>
            <NavBar />
            <Services />
            <Portfolio />
            <Footer />
        </>

    )
}

export default Service;

