import React from 'react';
import { Link } from 'react-router-dom';
import img from '../images/logo.png';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer mx-auto px-4 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid grid-cols-1 sm:grid-cols-12 gap-5 py-8 md:py-12 border-t  lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-1 sm:col-span-6 lg:col-span-4 mx-auto">
                            <div className="img border-b-4 p-8 text-black-600 text-center rounded-lg xl:w-100 mx-auto">
                                <img alt="card img" src={img} />
                                <div className='text-md font-medium text-gray-600'>
                                    <h5>Agileinfo Techytern solutions LLP , Muttakada
                                        Road, Aristo Junction,
                                        Thampanoor, Thiruvananthapuram,
                                        Kerala 695014</h5>
                                    <h5>Contact Info</h5>
                                    <h6><a href="tel:7907248735">7907248735,<a href="tel:7907259007">7907259007</a></a></h6>
                                    <h6><a href="mailto:info@agileinfoz.com">info@agileinfoz.com</a></h6>
                                </div>
                            </div>
                        </div>


                        {/* 3rd block */}
                        <div className="col-span-1 sm:col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <Link to="/contact#contact"
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                        Web Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/contact#contact"
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                        Mobile App Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/contact#contact"
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                        Domain and Hosting</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/contact#contact"
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                        General IT Consultations</Link>
                                </li>
                            </ul>
                        </div>
                        {/* 2nd block */}
                        <div className="col-span-1 sm:col-span-6 md:col-span-6 lg:col-span-1 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <Link
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                        to="/about">
                                        About
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                        to="/service">Services</Link>
                                </li>
                                <li className="mb-2">
                                    <Link
                                        className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                        to="/contact#contact">Contact</Link>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div
                            className="col-span-1 sm:col-span-6 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                            <div className="text-xl mb-6">
                                Social Media Links.
                            </div>

                            <div className="text-md font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <a href=" https://wa.me/7907248735"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Twitter">
                                            <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                                <path
                                                    d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="ml-4">
                                        <a href="https://www.facebook.com/Agileinfo23"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Facebook">
                                            <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                <path
                                                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="ml-4">
                                        <a href="https://www.instagram.com/agileinfo_techytern/"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Facebook">
                                            <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="ml-4">
                                        <a href="https://www.linkedin.com/company/agile-infoz/"
                                            className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Facebook">
                                            <svg className="w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                                <path
                                                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>

                            </div>

                        </div>

                    </div>

                    <div className="col-span-12 flex flex-wrap items-center md:justify-between 
                        justify-center mx-auto">
                        <div className="w-full md:w-12/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-black-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{" "}
                                <a href="https://www.agileinfoz.com/" className=" hover:text-gray-900">
                                    @ Agileinfo Techytern solutions LLP,
                                </a> All rights reserved. Designed By Agileinfo
                            </div>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;