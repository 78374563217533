import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <a className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth href="/">
                Home
            </a>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/service">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/gallery">
            Gallery
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact#contact">
                Contact 
            </HashLink>
            <a className="px-4 font-extrabold text-gray-500 hover:text-blue-900" href="https://agileinfos.com/">
                Internship 
            </a>
           
        </>
    )
}

export default NavLinks;
