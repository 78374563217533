import React from 'react';
import Footer from '../components/Footer';
import Image from '../components/image';
import NavBar from '../components/Navbar/NavBar';


const Gallerys = () => {
    return (
        <>
            <NavBar />
            <Image />
            <Footer />
        </>

    )
}

export default Gallerys;

