import React from 'react';
import Slider from 'react-slick';
import laf from '../images/clients/1af.jpg';
import iso from '../images/clients/iso.jpg';
import start from '../images/clients/start.png';
import mission from '../images/clients/mission.png';
import msme from '../images/clients/msme.jpg';
import up from '../images/clients/up.png';
import download from '../images/clients/download.png';
import loggg from '../images/clients/loggg.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css"

const clientImage = {
    height: 'auto',
    width: 'auto',
    mixBlendMode: 'colorBurn'
};

const Clients = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="container-fluid">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Certifications</h2>
                </div>

                <div className="w-100 mx-4" data-aos="fade-in" data-aos-delay="200">
                    <Slider {...settings}>
                        <div className="text-center transition-all ease-in hover:opacity-100">
                            <img style={clientImage} src={up} alt="client" />
                        </div>
                        <div className="text-center transition-all ease-in hover:opacity-100">
                            <img style={clientImage} src={mission} alt="client" />
                        </div>
                        <div className="text-center  transition-all ease-in hover:opacity-100">
                            <img style={clientImage} src={iso} alt="client" />
                        </div>
                        <div className="text-center  transition-all ease-in hover:opacity-100">
                            <img style={clientImage} src={msme} alt="client" />
                        </div>
                        <div className="text-center transition-all ease-in hover:opacity-100">
                            <img style={clientImage} src={laf} alt="client" />
                        </div>
                        <div className="text-center  transition-all ease-in-out hover:opacity-100">
                            <img style={clientImage} src={start} alt="client" />
                        </div>
                        <div className="text-center  transition-all ease-in-out hover:opacity-100">
                            <img style={clientImage} src={download} alt="client" />
                        </div>
                        <div className="text-center transition-all ease-in-out hover:opacity-100">
                            <img style={clientImage} src={loggg} alt="client" />
                        </div>
                    </Slider>
                </div>
            </section>
        </div>
    );
}

export default Clients;
