import React from 'react';
import img from '../images/imgmain.webp';
import { HashLink } from 'react-router-hash-link';

const Intro = () => {
    return (
        <>
            <div className="m-auto p-2 md:p-12 h-5/6" id='about' >

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <h1 className="text-2xl  text-blue-900 font-bold my-4">About US</h1>
                        <h3 className="text-3xl  text-blue-900 font-bold">Agileinfo Techytern Solutions LLP</h3>
                        <div>
                            <p className='my-2 text-m text-gray-600 '>At our development company, we leverage technology to drive innovation and solve complex challenges. Our diverse team thrives on collaboration and creativity to deliver cutting-edge solutions.
                            </p>
                        </div>
                        <div>
                            <p className='my-2 text-m text-gray-600 '>
                            We specialize in web development, nurture talent through internships, and redefine academic excellence with impactful projects. Partner with us to transform your digital aspirations into reality. Welcome to the AgileInfo Internship Program – Where Dreams Meet Solutions.
                            </p>
                        </div>
                        <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
                        to="/contact#contact">
                     Contact us
                      <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                      </HashLink>
                       
                    </div>
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 ">
                        <img alt="card img" className="animate-float rounded-t float-right" src={img} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;