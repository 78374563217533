import React from 'react';

import img from '../images/gallery/18.jpg';
import img1 from '../images/gallery/17.jpg';
import img2 from '../images/gallery/16.jpg';
import img3 from '../images/gallery/15.jpg';
import img4 from '../images/gallery/14.jpg';
import img5 from '../images/gallery/13.jpg';
import img6 from '../images/gallery/12.jpg';
import img7 from '../images/gallery/11.jpg';
import img8 from '../images/gallery/10.jpg';
import img9 from '../images/gallery/9.jpg';
import img10 from '../images/gallery/8.jpg';
import img11 from '../images/gallery/04.jpg';
import img12 from '../images/gallery/05.jpg';
import img13 from '../images/gallery/06.jpg';
import img14 from '../images/gallery/07.jpg';
import img15 from '../images/gallery/08.jpg';
import img16 from '../images/gallery/7.jpg';
import img17 from '../images/gallery/6.jpg';
import img18 from '../images/gallery/5.jpg';
import img19 from '../images/gallery/4.jpg';
import img20 from '../images/gallery/3.jpg';
import img21 from '../images/gallery/2.jpg';
import img22 from '../images/gallery/1.jpg';












const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Gallery</h2>

                   
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img} />
                        </div>

                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img1} />
                        </div>

                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img2} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img3} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img4} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img5} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img6} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img7} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img8} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img9} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img10} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img11} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img12} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img13} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img14} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img15} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img16} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img17} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img18} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img19} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img20} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img21} />
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img22} />
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Services;