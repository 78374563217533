import React from 'react';
import img from '../images/webPage.png';
import img2 from '../images/mobile.png';
import img3 from '../images/host.png';
import img4 from '../images/it.png';
import img5 from '../images/data-science-.png';

import img6 from '../images/FULLSTACK.png';
import img7 from '../images/SOFTWARE.png';
import IMG8 from '../images/DIGITAL.png';
import img9 from '../images/TEST.png';
import IMGE10 from '../images/WEB.png';


const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>

                    
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] 
                                transition duration-1000 ease-in-out w-100" src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Web Development</h2>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Mobile App Development</h2>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">Domain and Hosting Services</h2>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">General IT Consultations</h2>
                                <p className="text-md font-medium">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Internship Programs</h2>

                   
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                            <a href="https://www.agileinfos.com" >
                                <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img5} />
                            </a>
                        </div>

                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                            <a href="https://www.agileinfos.com" >
                            <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img6} />
                            </a>
                        </div>

                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                            <a href="https://www.agileinfos.com" >
                            <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out w-50" 
                                src={img7} />
                            </a>
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                            <a href="https://www.agileinfos.com" >
                            <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={IMG8} />
                            </a>
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                            <a href="https://www.agileinfos.com" >
                            <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={img9} />
                            </a>
                        </div>
                        <div className="transition-all ease-in-out duration-300  overflow-hidden 
                             hover:text-white  shadow-1xl p-3 group">
                            <a href="https://www.agileinfos.com" >
                            <img alt="card img" className="rounded-t group-hover:scale-[1.1] 
                                transition duration-1000 ease-in-out" 
                                src={IMGE10}/>
                            </a>
                        </div>


                    </div>
                </div>
            </section>


        </div>
    )
}

export default Services;