import React from 'react';
import Clients from '../components/Clients';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Intro from '../components/Intro';



const About = () => {
    return (
        <> 
            <NavBar />
            <Intro />
            <Clients />
            <Footer />
        </>

    )
}

export default About;

