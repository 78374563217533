import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img from '../images/jewellery.jpg';
import img1 from '../images/edu.png';
import img2 from '../images/hospital.png';
import img3 from '../images/goodwill.png';

import bakeryVideo from '../images/bakery.mp4';
import chocolateVideo from '../images/chocolate.mp4';
import cookiesVideo from '../images/cookies.mp4';
import pastryVideo from '../images/pastry.mp4';

const Portfolio = () => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [videoId, setVideoId] = useState('');

    const openPopup = (id) => {
        setVideoId(id); // Set the video ID
        setPopupOpen(true); // Open the popup
    };

    const closePopup = () => {
        setPopupOpen(false); // Close the popup
        setVideoId(''); // Reset the video ID
    };

    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        {/* Video cards */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden 
                            text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-sm">
                                <a href="#" onClick={() => openPopup('pastry')}>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition
                                        duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Ecommerce Website </h2>
                                </a>
                            </div>
                            <div className="flex justify-center my-4">
                                <button onClick={() => openPopup('pastry')} className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full my-4 text-lg shadow-xl rounded-xl">
                                    View Demo
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden 
                            text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-sm">
                            <a href="#" onClick={() => openPopup('')}>
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition
                                        duration-1000 ease-in-out" src={img1}/>
                                    <h2 className="font-semibold my-4 text-2xl text-center">E-learning Platform </h2>
                                </a>
                            </div>
                            <div className="flex justify-center my-4">
                                <button onClick={() => openPopup('')} className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full my-4 text-lg shadow-xl rounded-xl">
                                    View Demo
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden 
                            text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-sm">
                            <a href="#" onClick={() => openPopup('')}>
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition
                                        duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Hospital Management System</h2>
                                </a>
                            </div>
                            <div className="flex justify-center my-4">
                                <button onClick={() => openPopup('')} className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full my-4 text-lg shadow-xl rounded-xl">
                                    View Demo
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden 
                            text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-sm">
                            <a href="#" onClick={() => openPopup('bakery')} >
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition
                                        duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Web Development</h2>
                                </a>
                            </div>
                            <div className="flex justify-center my-4">
                                <button onClick={() => openPopup('bakery')} className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full my-4 text-lg shadow-xl rounded-xl">
                                    View Demo
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Popup container */}
            {popupOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-xl">
                        {/* Video player */}
                        {videoId === 'bakery' && (
                            <video
                                title="Bakery Demo Video"
                                width="560"
                                height="315"
                                controls
                            >
                                <source src={bakeryVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {videoId === 'chocolate' && (
                            <video
                                title="Chocolate Demo Video"
                                width="560"
                                height="315"
                                controls
                            >
                                <source src={chocolateVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {videoId === 'cookies' && (
                            <video
                                title="Cookies Demo Video"
                                width="560"
                                height="315"
                                controls
                            >
                                <source src={cookiesVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {videoId === 'pastry' && (
                            <video
                                title="Pastry Demo Video"
                                width="560"
                                height="315"
                                controls
                            >
                                <source src={pastryVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {/* Close button */}
                        <button onClick={closePopup} className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg focus:outline-none">
                            Close
                        </button>
                        
                    </div>
                </div>
            )}
        </>
    );
}

export default Portfolio;
